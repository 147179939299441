<template>
	<div class="register" id="register" v-cloak>
	    <header>
	        <div class="pwidth h100 flex">
	            <div  @click="()=>{$router.push({path:'/index'})}"  class="cursor">
	                <h1 class="f-24 w-160 h-70 m-r-20"><img class="w100  m-a" :src="$store.state.app.logourl" alt=""></h1>
					 <h1 class="f-24">B2B平台注册</h1>
	            </div>
	            <div  @click="()=>{$router.push({path:'/login'})}" class="cursor l-h-100">
	                <span>已有账号？</span> <span class="--color">立即登录</span>
	            </div>
	        </div>
	    </header>
	
	    <div class="section" v-if="!issuccessview">
	        <div class="pwidth form">
	            <div class="Stepbar">  
	                <ul>
	                    <li>
	                        <i :class="[next==0||next==1||next==2?'nexactive --bg':'']">1</i>
	                        <p class="c-9" :class="[next==0||next==1||next==2?'fontactive':'']">填写账号信息</p>
	                    </li>
	                    <b :class="[next==1||next==2?'nexactive --bg':'']"></b>
	                    <li>
	                        <i :class="[next==1||next==2?'nexactive --bg':'']">2</i>
	                        <p class="c-9" :class="[next==1||next==2?'fontactive':'']">填写企业信息</p>
	                    </li>
	                </ul>
	            </div>

	            <!-- 第一步 -->
	            <div class="formcontent" v-if="next==0">
	                <div class="zcone">
	                    <ul>
	                        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
	                            class="demo-ruleForm " style="width: 550px;">
	
	                            <!-- <el-form-item label="姓名" prop="cardname">
	                                <el-input v-model.number="ruleForm.cardname" placeholder="请填写负责人姓名"></el-input>
	                            </el-form-item> -->
	                            <el-form-item label="登录账号" prop="cardno" required style="width: 500px;">
	                                <el-input v-model.number="ruleForm.cardno" placeholder="请设置账号"></el-input>
	                            </el-form-item>
								<el-form-item label="密码" prop="passwd" required style="width: 500px;">
								    <el-input type="password" v-model="ruleForm.passwd" placeholder="请设置您的登录密码"
								        autocomplete="off"></el-input>
								</el-form-item>
								<el-form-item label="确认密码" prop="passwd2" required style="width: 500px;">
								    <el-input type="password" v-model="ruleForm.passwd2" placeholder="请再次输入您的密码"
								        autocomplete="off"></el-input>
								</el-form-item>
	                            <el-form-item label="手机" prop="phone" required>
	                                <el-input class="el-phone" style="width: 290px;" maxlength="11" v-model.number="ruleForm.phone"
	                                    placeholder="请填写负责人联系方式">
	                                </el-input>
	                                <span class="senyzm" :class="{'senyzmactive':disabled}" @click="code"
	                                    :disabled="disabled">{{codename}}</span>
	                            </el-form-item>
	                            <el-form-item label="验证码" prop="code" required required style="width: 500px;">
	                                <el-input v-model="ruleForm.code" maxlength="4" placeholder="请输入验证码"></el-input>
	                            </el-form-item>
	                            
	                            <div class="next1btn">
	                                <p><i class="radio-m" :class="{'radio':radios}" @click="radio"></i><span>
	                                        创建网站账号的同时，我同意遵守 </span>
	                                    <span class="c-b14e cursor" @click="xieyi(142)">《服务协议》</span>和 <span  @click="xieyi(142)" class="c-b14e cursor">《隐私条款》</span>
									</p>
	                                <el-button type="primary" class="onebtn" @click="submitForm('ruleForm',1)">下一步
	                                </el-button>
	                            </div>
	                        </el-form>
	                    </ul>
	                </div>
	            </div>
	
	            <!-- 第2步 -->
	            <div class="formcontent flex" v-if="next==1">
	                <div action="">
	                    <ul>
	                        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
	                            class="demo-ruleForm">
								
								<el-form-item label="联系人" prop="contactperson" required> 
									<el-input v-model="ruleForm.contactperson"
										placeholder="请填写您公司的第一联系人"></el-input>
								</el-form-item>
								
	                            <el-form-item label="单位名称" prop="companyname" required>
	                                <el-input v-model="ruleForm.companyname" placeholder="请输入营业执照上面的单位名称"></el-input>
	                            </el-form-item>
	                        
								<el-form-item label="证件号码" prop="brancertnum" required>
									<el-input v-model="ruleForm.brancertnum" placeholder="请输入营业执照上面的证件号码"></el-input>
								</el-form-item>
	                        
	                           <!-- <el-form-item label="联系方式" prop="contacttel">
	                                <el-input v-model="ruleForm.contacttel" maxlength="11" placeholder="请填写联系方式"></el-input>
	                            </el-form-item> -->

	                           <!-- <el-form-item label="所在区域" prop="cityids" class="diqu">
									<div class="flex" >
										<el-select v-model="ruleForm.provinceid" placeholder="请选择"  @change="handleChangecity">
										    <el-option v-for="item in province" :key="item.taobaoid" :label="item.name" :value="item.taobaoid"></el-option>
										</el-select>
										  
										<el-select  class="m-l-10 m-r-10" v-model="ruleForm.cityid" placeholder="请选择" @change="handleChangearea">
										     <el-option v-for="item in city" :key="item.taobaoid" :label="item.name" :value="item.taobaoid"></el-option>
										</el-select>
										   
										<el-select  v-model="ruleForm.districtid" placeholder="请选择">
										      <el-option v-for="item in area" :key="item.taobaoid" :label="item.name" :value="item.taobaoid"></el-option>
										</el-select>
	                              
									</div>
	                            </el-form-item> -->
								
								
	
	                            <el-form-item label="分销商编码" prop="salesmannum" class="diqu" required>
	                                <el-cascader :options="optionssalesmannum" v-model="ruleForm.salesmannum" class="w100" placeholder="请填写的分销商编码"></el-cascader>
	                            </el-form-item>
	
	                            <el-form-item class="btns">
	                                <el-button class="" type="primary" @click="Previous(0)"><i click="c-f">上一步</i></el-button>
	                                <el-button type="primary" @click="submitForm('ruleForm',3)">完成注册</el-button>
	                            </el-form-item>
	                        </el-form>
	                    </ul>
	                </div>
					
					<div>
						<el-upload class="upload-demo" drag action="#" multiple :auto-upload="false" :on-change="handleAvatarSuccess">
							
							
							<div class="relative h-215" v-if="ruleForm.brancertimg">
								<img class="w100" :src="ruleForm.brancertimg" alt="">
								<div class="h-35 bg-0-04 w100 absolute l-0 b-0 l-h-35 c-f">重新上传</div>
							</div>
							<div v-else>
								<i class="el-icon-upload"></i>
								<div class="el-upload__text">
								  <em>点击上传营业执照</em>
									<div class="c-9 m-t-10 f-12">(或将文件拖到此处)</div>
								</div>
							</div>
						  <div class="el-upload__tip tc f-14 m-t-5" slot="tip"><i class="c-ff3">*</i> 证件图片</div>
						</el-upload>
						
					</div>
	            </div>
	        </div>
	
	    </div>
	
	
	
	    <div class="pwidth bg-f orderdetal" v-else>
	        <div class="orderimg">
	            <img src="../../assets/img/succsee.png" alt="">
	            <div class="wflex">
	                <span class="f-26">注册信息已提交，等待业务员进行审核！</span>
	                <span class="f-16 m-t-10 c-ffa">审核情况将以短信的形式发送到注册的手机号</span>
	            </div>
	        </div>
	        <div class="message">
			
	        </div>
	
	        <div class="lookorder tc">
	            <div class="--bg p-0-20"><div class="c-f" @click="()=>{$router.push({path:'/goodslist'})}" href="/view/goods/goodslist.html">前往商城看看</div></div>
	        </div>
	    </div>
		
		<qualifications class="bg-f"/>
	</div>
</template>

<script>
	export default {
		data: function data() {
			var _this = this,
				_ref;
		
			// 第一步验证----------------------------------------------
			var checkcardname = function checkcardname(rule, value, callback) {
				if (!value) {
					return callback(new Error('姓名不能为空'));
				} else {
					callback();
				}
			};
		
			var checkcardno = function checkcardno(rule, value, callback) {
				if (!value) {
					return callback(new Error('账号不能为空'));
				} else {
					callback();
				}
			};
		
			var checkphone = function checkphone(rule, value, callback) {
				if (!value) {
					_this.disabled = false;
					return callback(new Error('手机号不能为空'));
				}
		
				setTimeout(function() {
					if (!/[1]([3-9])[0-9]{9}$/.test(value)) {
						callback(new Error('手机号格式不正确'));
						_this.disabled = false;
					} else {
						_this.disabled = true;
						callback();
					}
				}, 1000);
			};
		
			var checkcode = function checkcode(rule, value, callback) {
				if (!value) {
					// this.disabled = false
					return callback(new Error('验证码不能为空'));
				} else {
					callback();
				}
			};
		
			var checkpasswd = function checkpasswd(rule, value, callback) {
				if (!value) {
					console.log('请输入密码')
					return callback(new Error('请输入密码'));
				} else {
					if (_this.ruleForm.passwd !== '') {
						_this.$refs.ruleForm.validateField('passwd2');
					}
		
					callback();
				}
			};
		
			var checkpasswd2 = function checkpasswd2(rule, value, callback) {
				if (!value) {
					
					return callback(new Error('请再次输入密码'));
				} else if (value !== _this.ruleForm.passwd) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			}; // 第二步验证----------------------------------------------
		
		
			var checkcompanyname = function checkcompanyname(rule, value, callback) {
				if (!value) {
					return callback(new Error('企业名称不能为空'));
				} else {
					callback();
				}
			};
		
			var checkdutyparagraph = function checkdutyparagraph(rule, value, callback) {
				if (!value) {
					return callback(new Error('纳税人识别号不能为空'));
				} else {
					callback();
				}
			};
		
			var checkwarehouseaddr = function checkwarehouseaddr(rule, value, callback) {
				if (!value) {
					return callback(new Error('仓库地址不能为空'));
				} else {
					callback();
				}
			};
		
			var checkcompanyaddr = function checkcompanyaddr(rule, value, callback) {
				if (!value) {
					return callback(new Error('公司的具体地址不能为空'));
				} else {
					callback();
				}
			};
		
			var checklegalperson = function checklegalperson(rule, value, callback) {
				if (!value) {
					return callback(new Error('法人名称不能为空'));
				} else {
					callback();
				}
			};
		
			var checkcontactperson = function checkcontactperson(rule, value, callback) {
				console.log(value);
		
				if (!value) {
					return callback(new Error('企业联系人不能为空'));
				} else {
					callback();
				}
			};
		
			var checkcontacttel = function checkcontacttel(rule, value, callback) {
				if (!value) {
					return callback(new Error('企业联系方式不能为空'));
				} else {
					callback();
				}
			};
		
			var checkcityids = function checkcityids(rule, value, callback) {
				console.log(_this.ruleForm);
		
				if (!_this.ruleForm.provinceid) {
					return callback(new Error('省份不能为空'));
				} else if (!_this.ruleForm.cityid) {
					return callback(new Error('所在城市市不能为空'));
				} else if (!_this.ruleForm.districtid) {
					return callback(new Error('所在区域不能为空'));
				} else {
					callback();
				}
			};
		
			var checksalesmannum = function checksalesmannum(rule, value, callback) {
				if (!value) {
					return callback(new Error('分销商编码不能为空'));
				} else {
					callback();
				}
			};
			var checkbrancertnum = function checkbrancertnum(rule, value, callback) {
				if (!value) {
					return callback(new Error('证件号码不能为空'));
				} else {
					callback();
				}
			};
			
			
		
			var checkerporgno = function checkerporgno(rule, value, callback) {
				if (!value) {
					return callback(new Error('分公司不能为空'));
				} else {
					callback();
				}
			}; // 第三步
		
		
			return _ref = {
				// -----表单验证------------
				disabled: true,				//验证码
				codename: '发送验证码',				//
				time: 60,				//验证码时间
				ruleForm: {
					cardname: '',					//姓名
					cardno: '',					//账号
					phone: '',					//手机
					code: '',					//手机
					passwd: '',					//密码
					passwd2: '',					//密码2
					companyname: '', //企业名称
					// dutyparagraph: '', //纳税人识别号
					// warehouseaddr: '', //仓库地址
					// companyaddr: '', //企业地址
					// legalperson: '', //企业法人
					contactperson: '', //企业第一联系人
					brancertnum:'', //营业执照证件号码
					brancertimg:'', //营业执照证件图片
					contacttel: '',					//联系方式
					provinceid: '',					//省份ID
					cityid: "",					//市ID
					districtid: '',					//区ID
					erporgno: '', //分公司
					// districtid: '', ///区/镇/县ID
					salesmannum: '', //分销商
					// url: urlhost, //分销商
					// prtcompanycode: '', //上级编码
					// companycertinfos: [{
					//     certtype: '', //证件类型
					//     certnum: '', //证件号码
					//     certvaliddateString: '', //证件有效期 
					//     businessscope: [], //经营范围
					//     file: '' //证件图片
					// }],
					// getcerttype: [], //获取证件类型
					// getbusinessscope: [] //获取经营范围
					
					
		
				},
				// 验证规则
				rules: {
					cardname: [{validator: checkcardname,trigger: 'blur'}],
					cardno: [{validator: checkcardno,trigger: 'blur'}],
					phone: [{validator: checkphone,trigger: 'blur'}],
					code: [{validator: checkcode,trigger: 'blur'}],
					passwd: [{validator: checkpasswd,trigger: 'blur'}],
					passwd2: [{validator: checkpasswd2,trigger: 'blur'}],
					// 二————————————————————————————————————————
					companyname: [{validator: checkcompanyname,trigger: 'blur'}],
					contactperson: [{validator: checkcontactperson,trigger: 'blur'}],
					contacttel: [{validator: checkcontacttel,trigger: 'blur'}],
					salesmannum: [{validator: checksalesmannum,trigger: 'blur'}],
					brancertnum: [{validator: checkbrancertnum,trigger: 'blur'}],
					cityids: [{validator: checkcityids,trigger: 'change'}] ,
					// erporgno: [{
					//     validator: checkerporgno,
					//     trigger: 'change'
					// }],
		
				},
				// -----表单验证------------
				// 地址信息
				options: [],
				optionProps: {
					value: 'taobaoid',
					label: 'name',
					children: 'childrens'
				},
				province: [],				//省
				city: [],				//s市
				area: [],				//s市
				// 分销商编码数据
				optionssalesmannum: [],// 分公司数据
				optionsBranchOffice: [],// 经营范围
				optionsmanage: [],// 证件类型
				optionscerttype: [],
				// ————————————————————————————————————————————————————————————
				Must: "★ 必填",
				next: 0,				// 下一步
				issuccessview: false,				//提交后显示成功页面
				dialogImageUrl: '',				//照片上传
				dialogVisible: false,
				disabled:false,
				radios:false,
				btnab:false,
				fileIndex:0,
				
				certificatesimg:'',//营业执照
			}
		},
		components: {},
		created: function created() {
			this.getTenantid(()=> {
				console.log("注册"); // this.getTenantid();
				this.getallerpsaleuser(); //查询业务员
				// this.getoptionsBranchOffice()//查询分公司
				// this.getcerttypefn() //获取证件类型
				// this.getbusinessscopefn() //获取经营范围
			this.hqdz(); //获取地址
			})
		},
		methods: {
			
			// 提示
			errorTost: function errorTost(message) {
				this.$message({
					showClose: true,
					type: 'error',
					message: message
				});
			},
			
			//同意条款
			radio: function radio() {
				this.radios = !this.radios;
				console.log(this.radios);
			},
			
			// 证件表单验证
			submitForm: function submitForm(formName, index) {
				console.log(this.ruleForm); // 创建formdata
				
				this.$refs[formName].validate(valid=>{
					if (valid) {
						if (this.radios) {
							if (index == 1)this.$refs['ruleForm'].clearValidate();
							if (index > 2) {
								this.next = 1;
								if(!this.ruleForm.brancertimg) return this.$notify({title: '提示',message:'请上传证件照',type: 'error',duration:1000});
								this.submitTo();
							} else {
								this.next = index;
							}
						} else {
							this.$notify({title: '提示',message:'请勾选条款',type: 'error',duration:1000});
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			
			
			// 查询销售员（业务员）列表
			getallerpsaleuser: function getallerpsaleuser() {
				this.requset('/baseinfo/allerpsaleuser', {}).then(res=>{
					if (res.status == 'success') {
						for (var i in res.data) {
							res.data[i]['value'] = res.data[i].userid;
							res.data[i]['label'] = res.data[i].username;
						}
		
						this.optionssalesmannum = res.data;
					}
				});
			},
			
			// 查询所有分公司
			getoptionsBranchOffice: function getoptionsBranchOffice() {
				var self = this;
				this.requset('/nb2b/baseinfo/allerporg.kb', {} ).then(res=>{
					if (res.status == 'success') {
						for (var i in res.data) {
							res.data[i]['value'] = res.data[i].orgno;
							res.data[i]['label'] = res.data[i].orgname;
						}
						self.optionsBranchOffice = res.data;
					}
				})
				
			},
			
			
			// 完成注册 发送信息到后台
			submitTo: function submitTo() {

				var loading = this.$loading({lock: true,text: '正在提交 请稍后 ...',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
				setTimeout(()=>{
					var ruleForm = this.ruleForm;
					var companycertinfos = ruleForm.companycertinfos;
				
					

					var formData = new FormData();
					Object.keys(ruleForm).forEach(function(key) {
						formData.append(key, ruleForm[key]);
						if (key == 'companyname') formData.append("companyinfo.".concat(key), ruleForm[key]);
						if (key == 'dutyparagraph') formData.append("companyinfo.".concat(key), ruleForm[key]);
						if (key == 'warehouseaddr') formData.append("companyinfo.".concat(key), ruleForm[key]);
						if (key == 'companyaddr') formData.append("companyinfo.".concat(key), ruleForm[key]);
						if (key == 'legalperson') formData.append("companyinfo.".concat(key), ruleForm[key]);
					
						if (key == 'contacttel') formData.append("companyinfo.".concat(key), ruleForm[key]);
						if (key == 'provinceid') formData.set("provinceid", ruleForm[key]);
						if (key == 'cityid') formData.set("cityid", ruleForm[key]);
						if (key == 'districtid') formData.set("districtid", ruleForm[key]);
						if (key == 'erporgno') formData.append("companyinfo.".concat(key), ruleForm[key]);
						if (key == 'prtcompanycode') formData.append("companyinfo.".concat(key), ruleForm[key]);

						if (key == 'brancertnum') formData.append("companyinfo.".concat(key), ruleForm[key]);
						if (key == 'brancertimg') formData.append("companyinfo.".concat(key), ruleForm[key]);
						if (key == 'contactperson') formData.append("companyinfo.".concat(key), ruleForm[key]);
						if (key == 'salesmannum') formData.append("companyinfo.".concat(key), ruleForm[key][0]);
						
					});
					var _loop = function _loop(i) {
						Object.keys(companycertinfos[i]).forEach(function(key) {
							formData.append("companycertinfos[".concat(i, "].").concat(key), companycertinfos[i][key]);
						});
					};
		
					for (var i in companycertinfos)_loop(i);
					console.log('ruleForm99',ruleForm); // 创建formdata
					
					// 删除多余的东西
					formData.delete('companycertinfos');
					formData.delete('companyname');
					formData.delete('dutyparagraph');
					formData.delete('warehouseaddr');
					formData.delete('companyaddr');
					formData.delete('legalperson');
					formData.delete('contactperson');
					formData.delete('contacttel');
					formData.delete('cityids');
					formData.delete('salesmannum');
					formData.delete('erporgno');
					formData.delete('prtcompanycode'); // POST 开发完成 /b2bmembercard/register
				

					this.uploadrequset('/b2bmembercard/register',formData).then(res=>{
						loading.close();
						// var data = JSON.parse(data);
						if (res.status == 'success') {
							this.$notify({title: '提示',message: '恭喜你，资料已完成交',type: 'success',duration:2000});
							this.issuccessview = true;
						} else {
							this.$notify({title: '提示',message: res.message,type: 'error',duration:2000});
						}
					})

				}, 1000);
			},
			
			
			// 选择省市
			handleChangecity: function handleChangecity(value) {
				console.log(value);
				this.ruleForm.cityid = '';
				this.ruleForm.districtid = '';
				this.hqdz(value);
			},
			// 选择区域
			handleChangearea: function handleChangearea(value) {
				this.ruleForm.districtid = '';
				this.hqdz(value, 'area');
			},
			
			
			// 获取省份地址 POST 开发完成 /nb2b/baseinfo/region.kb  '
			hqdz: function hqdz(code, area) {
				var _this5 = this;
		
				var options = this.options;
				var taobaoid = code ? code : 1;
				var Url = '/memberfront/getcitycode';
				
				this.requset(Url, {code: taobaoid}).then(function(res) {
						if (area) {
							_this5.area = res.data;
						} else {
							if (code) {
								_this5.city = res.data;
							} else {
								_this5.province = res.data;
								sessionStorage.setItem('province', JSON.stringify(res.data));
							}
						}
					});
				
		
			},
			
			// 上一步
			Previous: function Previous(i) {
				this.next = i;
				this.$refs['ruleForm'].clearValidate();
			},
			

			// 发送验证码
			code: function code() {
				var _this6 = this;
				var tInterval;
				if (!this.ruleForm.phone) {
					this.$notify({title: '提示',message: '请输入手机号',type: 'error',duration:1000});
				} else {
					if (this.time < 60) {
						this.disabled = false;
						return;
					}
		
					clearInterval(tInterval);
					tInterval = setInterval(function() {
						_this6.disabled = false;
						if (_this6.time > 0) {
							_this6.codename = _this6.time-- + ' 秒后再试';
						} else {
							_this6.codename = '发送验证码';
							_this6.time = 60;
							_this6.disabled = true;
							clearInterval(tInterval);
						}
					}, 1000); 

					var Url = '/smscode';
					var data = {
						phone: this.ruleForm.phone,
						type: 1,
						pid: 3
					};

					this.requset(Url, data).then(data=>{
							if(data.status=="success"){
								this.$notify({title: '提示',message: data.message,type: 'success',duration:3000});
							}else{
								clearInterval(tInterval);
								_this6.codename = '发送验证码';
								_this6.time = 60;
								_this6.ruleForm.phone = '';
								_this6.disabled = false;
								this.$notify({title: '提示',message: data.message + '，更换新手机试试',type: 'error',duration:3000});
							}
						})

			
				}
			},
			
			
			handleAvatarSuccess(e){
				console.log(e)
				var formData = new FormData();
				formData.append('file', e.raw);
				
				this.uploadimg(formData).then(data=>{
					console.log(data)
					if (data.status == 'success') {
						this.ruleForm.brancertimg=data.data
						this.$notify.success({title: '上传成功',message: '您的图片已上传成功',type: 'success'});
						
					} else {
						this.$notify.error({title: '错误',message: data.message,position: 'top-content'});
					}
				});
			},
			
			
			xieyi(detailid){
				this.$router.push({path:'/contact',query:{showdetail:true,detailid:detailid}})
			}
			
			
			
			
		}
		
	}
	
</script>

<style >
	.upload-demo{margin-left: 30px;width: 160px;}
	.el-upload-dragger{
		width: 160px;
		height: 215px;
		
	}
	.el-upload-list__item-name{display: none;}
</style>
